import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Technical Writing Services",
  whatWeOffer: {
    leftPart: "Technical topics are difficult. If not written correctly, they can be hard to read, even for experts. That's why they must be written well and appropriately organized.",
    rightPart: "Outsourcing such a task has been a problem for many entrepreneurs, but Podroom Creative wants to change that. Our team of in-house writers has tackled various technical topics over the years, and we can certainly help you."
  },
  processText: "Contact us right away, and we'll assess your requirements and get back to you with a quote.",
  testimonials: testimonials
}

const meta = {
  title: "Technical Writing Services",
  description: "Technical topics are difficult. If not written correctly, they can be hard to read, even for experts. That's why they must be written well and appropriately organized."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/technical-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)